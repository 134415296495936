





































































import { Component, Vue, Watch } from "vue-property-decorator";

@Component
export default class DayBook extends Vue {
  private mode = "overview";
  private name = "Voucher";
  private modulename = "voucher";
  private selectedItem: any = null;
  private tempItem: any = null;
  private data: any = { name: "", plural: "", singular: "", description: "" };
  private selections: Array<any> = [];
  private selectAll = false;
  private query: any = { dateFrom: this.today, dateTo: this.today, searchText: "", machineId: [] };
  private vouchers: Array<any> = [];

  @Watch('mode')
  modeChanged(value: string) {
    this.clear();
  }

  @Watch("selectAll")
  selectAllChanged(value: boolean) {
    if (value) {
      const count = this.items.length;
      this.selections = [];
      for (let i = 0; i < count; i++) {
        this.selections.push(this.items[i]._id);
      }
    } else {
      this.selections = []
    }
  }

  removeSelections () {
    const indices: Array<any> = this.selections.map(i => i);
    for (let i = 0; i < indices.length; i++) {
      this.remove(indices[i]);
    }
    this.selections = [];
    this.selectAll = false;
  }

  alter (voucherId: any) {
    this.$router.replace(`/dashboard/transactions/manufacturing/${voucherId}/alter`)
  }

  remove (voucherId: any) {
    this.$confirm(`This will remove this ${this.name}, Do you want to continue`, "Confirm", { confirmButtonText: 'Yes', cancelButtonText: 'No', type: 'warning'})
      .then(() => {
        this.$store.dispatch(`${this.modulename}/remove`,{ id: voucherId, callback: this.onRemoved });
      })
      .catch(() => {})
  }

  onSaved (e: any, d: any) {
    let msg = ""
    if (d) {
      this.clear();
      msg  = this.mode === "create" ? `${this.name} Successfully Created!` : `${this.name} Successfully Updated!`;
      this.$store.commit("SHOW_SUCCESS", msg)
    } else {
      msg = e.message;
      this.$store.commit("SHOW_ERROR", msg)
    }
  }

  onRemoved (e: any, d: any) {
    let msg = ""
    if (d) {
      this.clear();
      msg  = `${this.name} Successfully Removed!`;
      this.$store.commit("SHOW_SUCCESS", msg)
    } else {
      msg = e.message;
      this.$store.commit("SHOW_ERROR", msg)
    }
  }

  clear () {
    this.data = { name: "", plural: "", singular: "", description: "" }
    this.selectedItem = null;
    this.tempItem = null;
  }

  selectObject (sitem: any) {
    this.selectedItem = sitem;
    if (this.selectedItem) {
      const item = this.items.filter((i: any) => i._id && i._id.toString() === this.selectedItem.toString())[0];
      if (item) {
        this.data = JSON.parse(JSON.stringify(item));
      }
    }
  }

  get items () {
    const items = this.vouchers;
    return items;
  }
  
  get machines () {
    const items = this.$store.state.machine.items;
    return items;
  }

  loadItems () {
    const query: any = {}
    const dt = new Date(this.query.dateTo);
    const df = new Date(this.query.dateFrom)
    df.setUTCHours(0, 0 ,0, 0)
    dt.setUTCHours(23, 59, 59, 999);
    query.date = { $lte: dt, $gte: df }
    if (this.query.searchText) {
      query.$text = {$search: this.query.searchText}
    }

    if (this.query.machineId.length > 0) {
      query.machineId = {$in: this.query.machineId}
    }
    this.$store.dispatch(`${this.modulename}/find`, {query, callback: this.onLoaded});
  }

  onLoaded (e: any, d: any) {
    if (d) this.vouchers = d;
    else this.vouchers = [];
    this.selectAll = false;
    this.selections = [];
  }

  get canSave () {
    return this.data.name && this.data.plural && this.data.singular
  }

  get canRemove () {
    return this.selectedItem
  }

  mounted () {
    this.$store.commit('FOOTER_VISIBLE', false);
    this.$store.dispatch(`machine/load`)
  }

  formatDate(dt: Date) {
    const y = dt.getFullYear();
    const m = dt.getMonth() + 1;
    const d = dt.getDate();
    const ms = m < 10 ? `0${m}` : m.toString();
    const ds = d < 10 ? `0${d}` : d.toString();
    return `${y}-${ms}-${ds}`;
  }

  get today() {
    const dt = new Date(Date.now());
    return this.formatDate(dt);
  }

}
